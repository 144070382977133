import React, { useEffect } from 'react'
import './index.less'
import { Form, FormInstance } from 'antd'
import MiniCodeEditor from '../MiniCodeEditor'

type CodeProps = {
    form?: FormInstance
    formData: any
}

const Code: React.FC<CodeProps> = (props) => {

    const { form, formData } = props

    useEffect(() => {
        form?.setFieldsValue({
            code: formData?.code || ''
        })
    }, [formData])
    
    return (
        <div className='content1'>
            <Form.Item
                label='初始代码'
                name='code'
                required
                rules={[
                    // { required: true, message: '请输入示例代码', validateTrigger: "onSubmit", whitespace: true },
                ]}
                validateFirst
            >
                <div style={{width: '100%', height: 'calc(100vh - 254px)'}}>
                    <MiniCodeEditor value={formData?.code ?? ''} language={formData?.codeLanguage} onChange={(value: string) => {
                        form?.setFieldValue('code', value)
                    }}/>
                </div>
            </Form.Item>
        </div>
    )
}

export default Code