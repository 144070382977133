import React from 'react'
import './index.less'
import MiniRichEditor from '../MiniRichEditor'
import CodeHighlight from '../CodeHighlight'
import { Button } from 'antd'
import config from '@/config'
import postRobot from 'post-robot'

type PreviewProps = {
    formData: any
}

const Preview: React.FC<PreviewProps> = (props) => {

    const { formData } = props

    const onClick = () => {
        if (formData?.codeLanguage !== 'shell') {
            const win = window.open(`${config?.codePlayerURL}/embedded`)
            const data = {
                code: formData?.code,
                lang: formData?.codeLanguage,
                run: true
            }
            postRobot.send(win, "send", data)
        }
    }

    return (
        <div className='preview'>
            <div className='preview-title'>可视化预览</div>
            <div className='preview-content'>
                <p className='preview-content-title'>示例演示</p>
                <MiniRichEditor height={'auto'} readOnly border={false} value={formData?.title} />
                {
                    formData?.showCode && <CodeHighlight style={{margin: '18px 0'}} lang={formData?.codeLanguage} value={formData?.code} />
                }
                <Button type='primary' style={{cursor: formData?.codeLanguage === 'shell' ? 'default' : undefined}} onClick={onClick}>{formData?.buttonName}</Button>
            </div>
        </div>
    )
}

export default Preview