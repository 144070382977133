import React from 'react'
import './index.less'
import MiniRichEditor from '../MiniRichEditor'
import { Button } from 'antd'
import postRobot from 'post-robot'
import config from '@/config'

type PreviewProps = {
    formData: any
}

const Preview: React.FC<PreviewProps> = (props) => {

    const { formData } = props

    const onClick = () => {
        const win = window.open(config.codeTestURL)
        postRobot.send(win, "send", {
            title: formData?.topic ?? '',
            content: formData?.title ?? '',
            initCode: formData?.code ?? '',
            code: formData.code,
            lang: formData.codeLanguage,
            cases: formData.example,
            submitCases: formData.testExample,
        })
    }

    return (
        <div className='preview-container'>
            <div className='preview'>
                <div className='preview-title'>可视化预览</div>
                <div className='preview-content'>
                    <p className='preview-content-title'>编码测验</p>
                    <p className='preview-content-topic'>{formData?.topic}</p>
                    <MiniRichEditor height={'auto'} readOnly border={false} value={formData.title} />
                    <Button onClick={onClick} type='primary' style={{minWidth: '88px'}}>{formData?.buttonName}</Button>
                </div>
            </div>
        </div>
    )
}

export default Preview