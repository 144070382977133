import React, { useEffect, useState } from 'react'
import { Modal } from 'antd'
import './index.less'
import QuesAndAns, { Quesandans } from './QuesAndAns'


type ActivityProps = {
  params?: any
  template?: any        // 环境信息
  activity: Activity,
  show: boolean,
  close: () => void,
  finish?: (data: Quesandans) => void
}

export type Activity = {
  type: 'question', // 问答  实验   校验
  category: 'text' | 'video',  // 文档型课程   视频型课程
  mark: boolean, // 是否是评分模式
  data?: Quesandans
}



const ActivityForm: React.FC<ActivityProps> = (props) => {

  const { activity, show, close, finish } = props

  if (!show) {
    return null
  }

  return (
    <>
      <Modal
        open={show}
        wrapClassName={'ylzcc-modal'}
        onCancel={() => { close?.() }}
        footer={false}
        centered={true}
        width={'1020px'}
        maskClosable={false}
        destroyOnClose={true}
      >
        <QuesAndAns quesandans={activity?.data as Quesandans} category={activity?.category} mark={activity?.mark} close={close} finish={finish} />
      </Modal>
    </>
  )
}


export default ActivityForm
