import React, { useEffect, useState } from 'react'
import './index.less'
import { Button, Form, Modal, Space } from 'antd'
import ModalClose from '../ModalClose'
import Step from '../Step'
import Preview from './Preview'
import Code from './Code'
import Content from './Content'
import { handleTimeSecond } from '@/utils/time'
import Verify from './Verify'

type CodeTestProps = {
    open: boolean
    onClose?: () => void
    value?: any
    time?: boolean           // 是否需要显示时间控件  默认不需要
    initTime?: string        // 如果需要可以提供一个初始值
    veriryTime?: (speed: number, id?: string) => any// 如果需要时间空间还可以提供一个校验时间的控件
    finish?: (data: any) => Promise<any>
}

const defaultSteps = [
    {
        type: 'unfinish',
        title: '内容设置'
    },
    {
        type: 'unfinish',
        title: '初始代码设置'
    },
    {
        type: 'unfinish',
        title: '校验设置'
    },
    {
        type: 'unfinish',
        title: '预览'
    },
]

// 编码测验
const CodeTest: React.FC<CodeTestProps> = (props) => {

    const { open, onClose, value, time = false, initTime = '00:00:00', veriryTime, finish } = props

    const [form] = Form.useForm()
    const [steps, setSteps] = useState(defaultSteps.map(item => ({...item})))   // 步骤
    const [current, setCurrent] = useState(0)          // 当前步骤
    const [loading, setLoading] = useState(false)      // 保存加载
    const [formData, setFormData] = useState({})       // 表单数据

    // 保存
    const save = async () => {
        const data = {...formData}
        setLoading(true)
        await finish?.(data)
        setLoading(false)
        onClose?.()
    }

    // 切换当前步骤
    const changeSetp = async (pre: number, next: number) => {
        if (pre === next) return
        
        // 向下需要判断是否可达，比如不能直接从第一步直接跳到第三部
        if (next > pre) {
            // 先校验当前的表单是否可以通过  第一步和第二步才有表单
            const newSteps = [ ...steps ]
            if ([0, 1, 2].includes(pre)) {
                const data = await form?.validateFields().catch(() => { })
                if (!data) return
                // 针对时间的格式进行处理
                if (data?.time) {
                    data.speed = handleTimeSecond(data.time)
                    data.time = data.time.format('HH:mm:ss')
                }
                // 针对校验
                if (data?.verifyMode && data.verifyMode === 'NONE') {
                    setFormData({ ...formData, ...data, paramNum: undefined, example: undefined, testExample: undefined })
                } else {
                    setFormData({ ...formData, ...data })
                }
                newSteps[pre].type = 'finish'
                setSteps(newSteps)
            }
            for (let i = pre; i < next; i++) {
                const step = newSteps[i]
                if (step.type === 'unfinish') {
                    return
                }
            }
        }
        setCurrent(next)
    }

    useEffect(() => {
        if (open) {
            setLoading(false)
            setFormData(value ?? {})
            setSteps(value ? defaultSteps.map((item) => ({...item, type: 'finish'})) : defaultSteps.map(item => ({...item})))
            setCurrent(0)
        }
    }, [open, value])

    if (!open) return null

    return (
        <Modal
            open={open}
            wrapClassName='act-codetest-modal'
            onCancel={onClose}
            footer={false}
            closable={false}
            centered={true}
            width={896}
            maskClosable={false}
            destroyOnClose={true}
        >
            <ModalClose className='close' onClose={onClose} />
            <p className='title-top'>{value ? '编辑' : '添加'}编码测验</p>
            <Step onClick={changeSetp} steps={steps as any} current={current} style={{ marginBottom: '20px', padding: '0 50px' }} />

            <div className='content'>
                {
                    current === 3 ? <Preview formData={formData}/> :
                    <Form
                        name="act-codetest-modal-form"
                        colon={false}
                        autoComplete="off"
                        form={form}
                    >
                        {
                            current === 0 ? <Content disableTime={!(value && Object.keys(value)?.length)} formData={formData} form={form} time={time} initTime={initTime} veriryTime={veriryTime}/> :
                            current === 1 ? <Code form={form} formData={formData}/> :
                            current === 2 ? <Verify form={form} formData={formData} /> :
                            <></>
                        }
                    </Form>
                }
            </div>

            <Space align="center" size={30} style={{ display: 'flex', justifyContent: 'center', height: '32px', marginTop: '40px' }}>
                <Button style={{ minWidth: '88px' }} onClick={() => {current === 0 ? onClose?.() : changeSetp(current, current - 1)}}>{ current === 0 ? '取消' : '上一步'}</Button>
                <Button style={{ minWidth: '88px' }} loading={loading} type="primary" onClick={() => { current === steps.length - 1 ? save() : changeSetp(current, current + 1) }}>{current === steps.length - 1 ? '保存' : '下一步'}</Button>
            </Space>
        </Modal>
    )
}

export default CodeTest